import React from 'react'
import GatsbyImage from 'gatsby-image'
import Gallery from '@browniebroke/gatsby-image-gallery'

class Photo extends React.Component {
    render() {
        const photoData = this.props.data
        const description = photoData.description
        const showCaption = this.props.caption
        const aspectRatio = photoData.fluid.aspectRatio

        let orientation;
        if (aspectRatio >= 1.2) orientation = "figure-image-orientation--landscape";
        if (aspectRatio <= 0.8) orientation = "figure-image-orientation--portrait";
        if (aspectRatio > 0.8 && aspectRatio < 1.2) orientation = "figure-image-orientation--square";

        const image = [
            {
                thumb: photoData.fluid,
                full: photoData.fixed
            }
        ]

        const lightboxOptions = {
            enableZoom: false,
            imageTitle: description
        }

        return (
            <figure className={`figure ${orientation}`}>
                <Gallery images={image} lightboxOptions={lightboxOptions} />
                {showCaption != "hide" ? (
                    <figcaption className="figure__caption">{description}</figcaption>
                ) : ""}
            </figure>
        )
    }
}

export default Photo
